import { GetStaticPropsResult } from 'next';
import { ReactElement } from 'react';

import { DataFetcher, Metadata } from '@utils/DataFetcher';
import Layout from '@components/Layout/Layout';
import { CdsIndexPage } from 'page-templates/CdsIndexPage';
import CdsLayout from '@components/Layout/CdsLayout';
import { EnvironmentProps, getEnvProps } from '@utils/env';
import { FkIndexPage } from 'page-templates/FkIndexPage';
import { MostReadArticleType } from '@components/MostReadArticles/MostReadArticles';
export type { MostReadArticleType } from '@components/MostReadArticles/MostReadArticles';

import { ArticlePageProps, CategoryPageProps } from './[...slug]';
import { NextPageWithLayout } from './_app';

export type TopCategoriesAndArticlesType = Omit<
  CategoryPageProps,
  'metadata'
> & {
  topArticles: Omit<ArticlePageProps, 'metadata'>[];
};

export type FkIndexPageProps = {
  query?: string;
  title: string;
  topCategoriesAndArticles: TopCategoriesAndArticlesType[];
  topCategories: CategoryPageProps[];
  mostReadArticles: MostReadArticleType[];
  metadata: Metadata;
  mostSearchedTerms: string[];
};

export type NewsDataType = {
  title: string;
  content: string;
  subline: string;
  url?: string;
};

export type CdsIndexPageProps = {
  query?: string;
  title: string;
  topCategories: CategoryPageProps[];
  mostReadArticles: MostReadArticleType[];
  mostSearchedTerms: string[];
  metadata: Metadata;
  newsData: NewsDataType[];
};

export type IndexPageProps = CdsIndexPageProps | FkIndexPageProps;

const dataFetcher = new DataFetcher();

function isGppProps(
  _x: IndexPageProps,
  checkBoolean: boolean,
): _x is FkIndexPageProps {
  return checkBoolean;
}

const IndexPage: NextPageWithLayout<IndexPageProps & EnvironmentProps> = (
  props,
) => {
  const { isGpp } = props.metadata;

  return (
    <>
      {isGppProps(props, isGpp) ? (
        <FkIndexPage {...props} />
      ) : (
        <CdsIndexPage {...props} />
      )}
    </>
  );
};

IndexPage.getLayout = function getLayout(
  page: ReactElement,
  props: IndexPageProps & EnvironmentProps,
) {
  const mostSearchedTerms = props.mostSearchedTerms;
  const mostReadArticles = props.mostReadArticles;

  return isGppProps(props, props.metadata.isGpp) ? (
    <Layout
      query={''}
      isIndexPage={true}
      isSearchPage={false}
      title={props.title}
      slug={'/'}
      metadata={props.metadata}
      mostSearchedTerms={mostSearchedTerms}
      mostReadArticles={mostReadArticles}
      features={props.features}
    >
      {page}
    </Layout>
  ) : (
    <CdsLayout
      query={''}
      isSearchPage={false}
      isIndexPage={true}
      title={props.title}
      slug={'/'}
      metadata={props.metadata}
      mostSearchedTerms={mostSearchedTerms}
      mostReadArticles={mostReadArticles}
      features={props.features}
    >
      {page}
    </CdsLayout>
  );
};

export async function getStaticProps(): Promise<
  GetStaticPropsResult<IndexPageProps & EnvironmentProps>
> {
  const dataProps = dataFetcher.getIndexProps();

  if ('props' in dataProps) {
    return {
      ...dataProps,
      props: {
        ...dataProps.props,
        ...getEnvProps(),
      },
    };
  }
  return dataProps;
}

export default IndexPage;
