import { SimpleGrid, VStack } from '@neui/layout';
import {
  Typography,
  Headline as NeuiHeadline,
  Text,
  sandTheme,
  interaction_arrows_arrowRight,
  Headline,
} from '@neui/styleguide-commerzbank';

import { useTracker } from '@utils/snowplowTracking';
import { fkIconGroupInfo } from '@utils/iconGroupInfo';
import { categoryMap, CategoryInfo } from '@utils/categoryInfo';
import { Section } from '@components/neui-components/atoms/Section';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { ActionButton } from '@components/neui-components/molecules/ActionButton';
import { $t } from '@utils/i18n';
import { isGppDE } from '@utils/DataFetcher';
import { ChangeServicePortal } from '@components/ChangeServicePortal';
import { Image } from '@components/Image';

import { FkIndexPageProps } from '../pages/index';
import {
  ImageWrapper,
  SandSection,
  StyledIconLink,
  StyledUl,
  StyledLi,
} from './CdsIndexPage';

export const FkIndexPage = ({
  mostReadArticles,
  topCategories,
  metadata,
}: FkIndexPageProps) => {
  const { trackButtonClick } = useTracker(FkIndexPage.name);
  const { basename } = metadata;

  return (
    <>
      <Section>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          <Text type={'helper'}>{$t('SEARCH_SUBLINE')}</Text>
          <StyledUl>
            {fkIconGroupInfo(isGppDE).map((topic, index) => (
              <StyledLi key={index}>
                <ActionButton
                  icon={topic.icon}
                  look={'secondary'}
                  href={topic.url}
                  label={topic.label}
                  onClick={() => {
                    trackButtonClick?.(topic.label, 'self_service', []);
                  }}
                />
              </StyledLi>
            ))}
          </StyledUl>
        </VStack>
      </Section>
      <SandSection theme={'sand'} className={sandTheme}>
        <NeuiHeadline
          type="h3"
          renderAs="h2"
          textAlign={'left'}
          css={{ alignSelf: 'flex-start' }}
        >
          {metadata.isGppDE ? 'Gerade beliebt' : 'Currently popular'}
        </NeuiHeadline>
        <SimpleGrid
          spacing={{ base: '$space$component-8' }}
          columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
        >
          {mostReadArticles.map((article, index) => {
            const onClick = () => {
              trackButtonClick?.(article.slug, 'top_article', []);
            };
            return (
              <TeaserCard
                key={index}
                href={article.slug}
                onClick={onClick}
                hover
                footer={
                  <StyledIconLink
                    iconPosition="right"
                    animationDirection="none"
                    renderAs={Typography}
                    icon={interaction_arrows_arrowRight}
                    data-cy={'top-article'}
                  >
                    {metadata.isGppDE ? 'Mehr erfahren' : 'Read more'}
                  </StyledIconLink>
                }
              >
                <VStack spacing={{ base: 12, md: 16 }}>
                  <Typography
                    size={4}
                    weight={'medium'}
                    role="heading"
                    aria-level={3}
                  >
                    {article.title}
                  </Typography>
                  <Typography textAlign={'left'}>{article.excerpt}</Typography>
                </VStack>
              </TeaserCard>
            );
          })}
        </SimpleGrid>
      </SandSection>
      <Section>
        <VStack spacing={'$subsection'}>
          <NeuiHeadline
            type="h3"
            renderAs="h2"
            subline={
              metadata.isGppDE
                ? 'Hier finden Sie Informationen zu allen Themen rund um Produkte, Verwaltung, Online Banking und weitere Bankthemen.'
                : 'Here you will find information on all topics related to products, administration, online banking and other banking topics.'
            }
          >
            {metadata.isGppDE
              ? 'Unterstützung & Informationen'
              : 'Support & Information'}
          </NeuiHeadline>
          <SimpleGrid
            spacing={{ base: '$space$component-8' }}
            columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
          >
            {topCategories.map((category, index) => {
              const { slug, title } = category;
              const categoryInfo = categoryMap[basename].get(
                slug,
              ) as CategoryInfo;

              return (
                <TeaserCard
                  key={index}
                  href={slug}
                  onClick={() => {
                    trackButtonClick?.(title, 'category', []);
                  }}
                  hover
                  bordered
                  picture={
                    <ImageWrapper>
                      <Image
                        src={categoryInfo.icon as string}
                        alt=""
                        loading="lazy"
                      />
                    </ImageWrapper>
                  }
                  footer={
                    <StyledIconLink
                      iconPosition="right"
                      animationDirection="none"
                      renderAs={Typography}
                      icon={interaction_arrows_arrowRight}
                      data-cy={'category'}
                    >
                      {metadata.isGppDE ? 'Mehr erfahren' : 'Learn more'}
                    </StyledIconLink>
                  }
                >
                  <VStack spacing={{ base: 8, md: 16 }}>
                    <Typography
                      size={4}
                      weight={'medium'}
                      aria-level={3}
                      role="heading"
                    >
                      {title}
                    </Typography>
                    <Text textAlign={'left'} type="info">
                      {categoryInfo.description}
                    </Text>
                  </VStack>
                </TeaserCard>
              );
            })}
          </SimpleGrid>
        </VStack>
      </Section>
      <ChangeServicePortal />
    </>
  );
};
